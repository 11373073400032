import React, {useEffect, useState} from "react"
import { navigate } from "gatsby"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

    var user = null;
    if (hasMounted) {
      user = window.localStorage.getItem("user")
    }

    if (!user && location.pathname !== `/`) {
      navigate("/")
      return null
    }
    return <Component {...rest} />
}
export default PrivateRoute
