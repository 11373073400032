import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styles from './gallery.module.scss'

const Gallery = () => {
    const images = useGallery()

    return (
        <div className={styles.gallery}>
            {images.map(({ id, fluid }) => (
                <Img imgStyle={{border: '1px solid white'}} key={id} fluid={fluid} />
            ))}
        </div>
    )
}

export default Gallery
const useGallery = () => {
    const data = useStaticQuery(graphql`
      query {
        allFile(
            sort: { fields: [base] }
            filter: {relativeDirectory: {eq: "gallery"}}
        ) {
          nodes {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `);
  
    return data.allFile.nodes.map(node => ({
      ...node.childImageSharp, // Note that we're spreading the childImageSharp object here
      id: node.id,
    }));
  };