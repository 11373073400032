import React from "react"
import ListContent from "../../components/listContent"
import Content from "../../components/content"

export default function Info() {
  return (
    <section>
      <Content>
        <h1>Missä ja milloin?</h1>
        <h2>Lauantaina 28.5.2022 <br></br> Ravintola Harmooni, Jyväskylä</h2>
        
          <p>Saavuthan paikalle ravintola Harmooniin klo 14.30. Tarjolla on juomaa, musiikkia ja seurustelua.<br></br> Juhlien virallinen osuus alkaa klo 15.00. 
        </p>
        <p> Juhlapaikka sijaitsee Hannikaisenkadun ja Väinönkadun kulmauksessa, Matkakeskusta vastapäätä. Rakennuksella on kaksi osoitetta: Hannikaisenkatu 39 ja Väinönkatu 1, 40100 Jyväskylä.
          </p>
        <p> Meille on varattu ravintolasta Harmooni-sali ja Oskar-kabinetti sekä pieni puutarhaterassi rakennuksen eteläpuoleisessa päädyssä. Käynti juhlatilaan tapahtuu Hannikaisenkadun puolelta. Pääoven tunnistaa ovea reunustavasta tiiliholvikaaresta.</p>
        <p>Juhlapaikka on saanut nimensä siitä, että se toimi harmoonitehtaana 1900-luvun alkupuolella. Paikan tarinaan ja historiaan pääset tutustumaan <a href="https://www.harmooni.fi/tietoja/tarina/" target="_blank" rel="noopener noreferrer">tästä</a> </p>
        <hr></hr>
        <h2>Saapuminen </h2>
        <p>Juhlapaikka sijaitsee aivan Jyväskylän keskustassa, ja sinne on helppoa saapua niin läheltä kuin kauempaakin. Tila sijaitsee lyhyen kävelymatkan päässä sekä Matkakeskukselta (jonne tulevat kaukojunat ja linja-autot) että paikallisliikenteen pysäkeiltäkin. Matkakeskuksen sivut löytyvät <a href="https://visitjyvaskyla.fi/saapuminen/matkakeskus/">täältä. </a></p>
        <p>Jos kuitenkin päätät tulla omalla autolla, keskustassa on useita parkkitaloja ja parkkialueita. Niistä löydät lisätietoa <a href="https://jyvas-parkki.fi/wp-content/uploads/2020/02/Liikennekartta_2-2020.pdf" target="_blank" rel="noopener noreferrer">täältä</a>.</p>
      
       <hr></hr>
        <h2>Yöpyminen</h2>
        <p> Toivomme, että myös kauempaa tulevat vieraamme viihtyisivät kanssamme mahdollisimman pitkään. Juhlatilan läheltä löytyy useampia hotelleja, joihin jaksaa kävellä myös tanssimisesta väsynein jaloin. 
</p>
        <p>
        Yösijaa kannattaa tiedustella esimerkiksi seuraavista paikoista:</p>

        <ListContent>
          <ul>
          <li> <a href="https://www.hotelliyopuu.fi/"  target="_blank" rel="noopener noreferrer">Boutique hotelli Yöpuu</a>  (Täällä tarjoiltava aamupala on äänestetty Suomen parhaaksi hotelliaamiaiseksi.)
</li>
            <li>
              <a href="https://www.hotelliverso.fi/">Hotelli Verso </a> (Meidän mielestämme paras aamupala saattaa kuitenkin löytyä täältä.)
   </li>
 
            <li><a href="https://www.sokoshotels.fi/fi/jyvaskyla/solo-sokos-hotel-paviljonki" target="_blank" rel="noopener noreferrer">Solo Sokos hotel Paviljonki</a> </li>
          </ul>
        </ListContent>
      </Content>
    </section>
  )
}