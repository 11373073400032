import React from "react"
import Content from "../../components/content"
import Gallery from "../../components/gallery"

export default function Home() {
  return (
    <section>
      <Content>
        <h1>Tervetuloa - jälleen kerran!</h1>
        <p>Olemme muokanneet nettisivuille uuden infon. Huomaathan hivenen muuttuneen ajan ja paikan.</p>
        <p>Nähdään toukokuussa!</p>  
        
        <div style={{'textAlign':'center'}}><span role="img" alt="heart">❤️</span></div>
        <p>Annakaisa ja Markku</p>
      </Content>
      <div>
      <Gallery></Gallery>
      </div>
    </section>
  );
}

