import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Login from "./app/login"
import Songwish from "./app/laulutoive"
import Info from "./app/info"
import Muistaminen from "./app/muistaminen"
import Tamara from "./app/tamara"
import Welcome from "./app/tervetuloa"

const App = () => (
      <Router>
        <PrivateRoute path="/app/laulutoive" component={Songwish} />
        <PrivateRoute path="/app/info" component={Info} />
        <PrivateRoute path="/app/tamara" component={Tamara} />
        <PrivateRoute path="/app/muistaminen" component={Muistaminen} />
        <PrivateRoute path="/app/tervetuloa" component={Welcome} />
        <Login path="/" />
      </Router>
  )
export default App