
import * as React from "react"
import styles from './listContent.module.scss'

const ListContent = ({children}) => {
    return (
      <div className={styles.lista}>
        {children}
      </div>
  )
}
export default ListContent