import React, { useState, useContext } from "react"
import { Button, TextField } from "@material-ui/core"
import { FirebaseContext } from "../../components/firebase"
import { navigate } from "gatsby"

const Login = () => {
const [formValues, setFormValues] = useState({email: '', password: ''});
const {firebase} = useContext(FirebaseContext);
const [errorMessage, setErrorMessage] = useState('');

function handleSubmit(e) {
   e.preventDefault(); 
    
    firebase.login({email: formValues.email, password: formValues.password})
    .then(() => navigate('/'))
    .catch(error => {
      console.log(error);
      setErrorMessage('Ei tuolla sisälle pääse. Naputtelepa tarkemmin.');
    });
  }

function handleInputState(e) {
    e.persist();
    setErrorMessage('');
    setFormValues(currentValues => ({
        ...currentValues,
        [e.target.name]: e.target.value
    }));
}

  return (
    <section>
      <form onSubmit={handleSubmit} style={{marginLeft: '15%'}}>
        <TextField
          style={{ marginBottom: "1em" }}
          required
          value={formValues.email}
          name="email"
          onChange={handleInputState}
          placeholder="Käyttäjätunnus"
          type="email"
        />
        <TextField
          style={{ marginBottom: "1em" }}
          required
          value={formValues.password}
          name="password"
          onChange={handleInputState}
          placeholder="Salasana"
          type="password"
        />
        {!!errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
        <Button style={{ marginTop: "1em" }} variant="outlined" type="submit">
          Kirjaudu
        </Button>
      </form>
    </section>
  )
  }

export default Login