import React from "react"
import Content from "../../components/content"

export default function Muistaminen() {
  return (
    <section>
      <Content>
      <h1>Muistaminen</h1>
      <p>Toivoisimme, että jokainen toisi juhlaan mukanaan lyhyen kirjoitetun muiston, jonka liittäisi osaksi vieraskirjaa. Muisto voi liittyä jompaankumpaan meistä tai meihin yhdessä. Se voi olla aivan tuore tai vuosien takaa. Myös valokuvat olisivat ihania. Pienetkin hauskat yhteiset muistot ja tarinat lämmittävät mieltä.</p>
      <p> </p>
      <p>Tärkeintä meille on saada viettää juhlapäivää teidän kanssanne. Lahjojen sijaan meitä voi muistaa vaikka puheilla, musiikilla tai muulla ohjelmalla. Jos ramppikuume iskee, voi halutessaan myös auttaa meitä vähän eteenpäin haaveissamme yöpyä Lapissa lasi-iglussa revontulten alla, patikoida jylhissä maisemissa Lofooteilla, nähdä geysirit Islannissa tai seikkailla hobittien jalanjäljissä Uudessa-Seelannissa (aika monta matkahaavetta näemmä on koronavuoden aikana ehtinyt syntyä). </p>
      <p>Haavetilimme numero on </p>
      <p>FI70 5278 5020 0247 93</p>
      </Content>
    </section>
  )
}
