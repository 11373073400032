import React from "react"
import Content from "../../components/content"
import Wish from "../../components/wish"

export default function Tamara() {
  return (
    <section>
      <Content>
        <h1>Herzlich willkommen!</h1>
        <hr></hr>
        <h2>When and where?</h2>

        <p>Saturday 28th of may 2022.</p>
      
      
        <p>Restaurant Harmooni</p>
        <br></br>
        <p> We kindly ask you to arrive at the venue at 2.30 pm to enjoy a drink and live music. The official part starts at 3.00 pm.</p> <p>The restaurant is situated in the center of Jyväskylä. The restaurant has two addresses: Hannikaisenkatu 39 &amp; Väinönkatu 1, 40100 Jyväskylä. </p>

        <hr></hr>
        <h2>Arriving in Jyväskylä</h2>

        <p>Well, you already know how to get here. We will pick you up from anywhere at any time. </p>
        <hr></hr>
        <h2>Accommodation</h2>

        <p>The address of you hotel is Lounatuulentie 10 B, 40530 Jyväskylä. We have reserved you a room with double bed and a lake view. Complimentary breakfast is included.</p>
        <hr></hr>
        <h2>Music that makes you dance?</h2>
        <p>What is a party without music? Well, just a boring gathering. At this party we will treat you with live music and some good old fashioned mixed tapes. What kind of music makes you happy? What kind of music makes you feel like dancing? We kindly ask you to send some song requests (you can click “send request” more than once). </p>
      <Wish></Wish>
      </Content>
    </section>
  )
}