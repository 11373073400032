import * as React from "react"
import { Link} from "gatsby"
import styles from './wish.module.scss'

const Wish = () => {
    return (
        <section>
         <div className={styles.voc}>
          <p>Vocabulary you might need to request a song</p>
        <ul>
          <li> Toivoja = You </li>
          <li> Kappale = Song</li>
          <li> Artisti = Band </li>
          <li> Lähetä = Send </li>
        </ul>
        </div>
        <div className={styles.linkContainer}>
        <Link  to='../../app/laulutoive' >Click here</Link>
        </div>
   </section>
  )
}
export default Wish